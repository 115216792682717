interface TextProps {
  title: string,
  nextStep?: () => void,
  children?: React.ReactNode;
}

function Text(props: TextProps) {
  return (
    <div className="flex flex-col w-full max-w-md">
      <h1 className="text-4xl text-red-600 text-center mb-2">{props.title}</h1>
      {props.children}
      {props.nextStep ? <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-4" onClick={props.nextStep}>Volgende</button> : null}
    </div>
  );
}

export default Text;
