import React, { Component } from "react";
import { getRhumbLineBearing, getDistance } from 'geolib';

interface CompassState {
  latitude: number,
  longitude: number,
  orientationAlpha: number,
  initialOffset: number | null;
}

interface CompassProps {
  latitude: number,
  longitude: number,
  title: string,
  start: string[],
  finish: string[],
  nextStep: () => void,
}

function readableDistance(distance: number): string {
  if (distance > 1000) {
    const kms = (distance / 1000).toFixed(1);

    return `${kms} km`
  } else {
    return `${distance} m`
  }
}

class Compass extends Component<CompassProps, CompassState> {
  constructor(props: CompassProps) {
    super(props);
    this.state = {
      latitude: 52.206320, // Sterrenkroos 22
      longitude: 4.632270,
      orientationAlpha: 0.0,
      initialOffset: null
    }

    this.handleOrientation = this.handleOrientation.bind(this);
  }

  handleOrientation(event: DeviceOrientationEvent) {
    if (this.state.initialOffset === null) {
      if (event.absolute === true) {
        this.setState({ initialOffset: 0 })
      } else {
        this.setState({ initialOffset: event.alpha })
      }
    }

    if (event.alpha !== null && this.state.initialOffset !== null) {
      let alpha = event.alpha - this.state.initialOffset;
      if (alpha < 0) {
        alpha += 360;
      }

      this.setState({ orientationAlpha: alpha })
    }
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
      });
    }

    if (window.DeviceOrientationEvent && typeof DeviceOrientationEvent.requestPermission === 'function') {
      DeviceOrientationEvent.requestPermission()
        .then(response => {
          if (response === 'granted') {
            window.addEventListener('deviceorientation', this.handleOrientation, true);
          }
        })
    } else {
      window.addEventListener("deviceorientation", this.handleOrientation, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('deviceorientation', this.handleOrientation, false);
  }

  render() {
    const distance = getDistance(this.state, this.props);

    // bearing from GPS + orientation of device - 90 to compensate for the arrow pointing right by default
    const arrowAngle = getRhumbLineBearing(this.state, this.props) + this.state.orientationAlpha - 90;

    return (
      <div className="flex flex-col space-y-4 w-full max-w-md">
        <h4 className="text-4xl text-red-600 text-center">{this.props.title}</h4>
        {distance < 25 // Within 25 meter
          ? <>
            <p className="text-l text-gray-500 text-center ">{readableDistance(distance)}</p>
            {this.props.finish.map((message, index) => <p className="text-left my-2" key={index}>{message}</p>)}
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={this.props.nextStep}>Volgende</button>
          </>
          : <>
            {this.props.start.map((message, index) => <p className="text-left my-2" key={index}>{message}</p>)}
            <p className="text-9xl text-blue-600 text-center" style={{ transform: `rotate(${arrowAngle}deg)` }}>➤</p>
            <p className="text-l text-gray-500 text-center ">{readableDistance(distance)}</p>
          </>
        }
      </div>
    );
  }
}


export default Compass;
