import React, { useState } from 'react';

interface PuzzleProps {
  title: string,
  question: string,
  answer: string,
  finish?: string,
  children?: React.ReactNode;
  nextStep: () => void,
}

function Puzzle(props: PuzzleProps) {
  const [currentAnswer, setCurrentAnswer] = useState("");
  const isAnswerCorrect = (currentAnswer.trim().toLowerCase() === props.answer.toLowerCase())

  return (
    <div className="flex flex-col max-w-md w-full space-y-4">
      <h1 className="text-4xl text-red-600 text-center">{props.title}</h1>
      <p>{props.question}</p>
      {props.children}
      <p>
        <input
          className={`shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${isAnswerCorrect ? "border-green-300 bg-green-100" : null}`}
          onChange={event => setCurrentAnswer(event.target.value)}>
        </input>
      </p>
      {isAnswerCorrect
        ? <>
          {props.finish ? <p>{props.finish}</p> : null}
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-4" onClick={props.nextStep}>Volgende</button>
        </>
        : null
      }
    </div>
  );
}

export default Puzzle;
