interface VideoProps {
  youtubeId: string,
  nextStep: () => void,
}

function Video(props: VideoProps) {
  return (
    <div className="flex flex-col max-w-md w-full h-full">
      <div className="flex-grow">
        <iframe
          title="YouTube"
          className="w-full h-full"
          src={`https://www.youtube-nocookie.com/embed/${props.youtubeId}`}>
        </iframe>
      </div>
      <button className="flex-none bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={props.nextStep}>Volgende</button>
    </div>
  );
}

export default Video;
