import React, { useState } from 'react';

import useLocalStorage from './utility/localStorage'

import Compass from './components/Compass';
import Text from './components/Text';
import Video from './components/Video';
import Puzzle from './components/Puzzle';

import sudoku from './sudoku.png';
import lisanne from './lisanne.jpg';
import triangles from './triangles.png';

function App() {
  const [currentStep, setCurrentStep] = useLocalStorage('currentStep', 0);
  const [godMode, setgodMode] = useState(false);

  const components = [
    <Text title="Gefeliciteerd 💖" nextStep={nextStep}>
      <p className="text-left my-2">Gefeliciteerd Liefste! Dertig wordt je niet elke dag. Het is algemeen bekend dat hoe ouder je wordt, hoe zwaarder het leven wordt. Nu je dertig bent, is dat niet anders.</p>
      <p className="text-left my-2">Om je cadeaus dit jaar te verdienen moet de code van de schatkist gekraakt worden! Deze app begeleidt je daarbij. Zet hem op je homescreen zodat je er straks makkelijk bij kan.</p>
    </Text>,
    <Puzzle
      title="Even oefenen 💆‍♀️"
      question="Je moet onder andere puzzels oplossen en vragen beantwoorden. Om warm te worden beginnen we gemakkelijk. Wat is het telefoonnummer van Dennis? Vul het hieronder in."
      answer="0648656266"
      finish="✅ Correct. Zoals je ziet verschijnt er een blauwe 'volgende' knop als je het goed hebt. Simpel!"
      nextStep={nextStep} />,
    <Compass
      title="Speuren 🕵️‍♀️"
      start={[
        "Ook moet je op pad! Trek je schoenen dus aan 🚶‍♀️🚶‍♂️🐕‍🦺! We gaan de deur uit. Hieronder staat een pijl en een afstand. Aan jou om het punt te vinden.",
      ]}
      finish={[
        "👏 Je bent er!"
      ]}
      latitude={52.207830565271486} // parkeerplaats bij de tennis
      longitude={4.640779332911731}
      nextStep={nextStep} />,
    <Puzzle
      title="Quizvraag 💡"
      question="Je moet ook vragen beantwoorden om verder te gaan 😱. Welke bekende Nederlandse schilder staat hier centraal?"
      answer="Rembrandt"
      finish=""
      nextStep={nextStep} />,

    <Compass
      title="En door 👌"
      start={[
        "Goed gedaan! Tijd om weer op pad te gaan.",
      ]}
      finish={[
        "👏 Je bent er! Dat navigeren gaat wel goed dus."
      ]}
      latitude={52.20976344020318} // begin baan
      longitude={4.637982968582649}
      nextStep={nextStep} />,
    <Video youtubeId="uVo6UjbhYiM" nextStep={nextStep} />, // Sanne


    <Compass
      title="En nu?"
      start={[
        "Goed gedaan! Tijd om weer op pad te gaan.",
      ]}
      finish={[
        "Gewoon weer thuis dus 😜 tijd om aan de code van de schatkist te beginnen."
      ]}
      latitude={52.206320} // Sterrenkroos
      longitude={4.632270}
      nextStep={nextStep} />,
    <Puzzle
      title="Sudoku ✏️"
      question="Los de Sudoku op, het nummer in het gele vakje is het eerste cijfer van het slot op de kluis! (Je moet hem even natekenen want het is niet interactief)."
      answer="6"
      nextStep={nextStep}>
      <img src={sudoku} alt="Sudoku" />
    </Puzzle>,
    <Video youtubeId="noWFfpQRAjE" nextStep={nextStep} />, // Jos & Debbie
    // video van marc
    // video van marlieke

    <Compass
      title="Pak de auto 🚘"
      start={[
        "We gaan nu wat verder. Laat Dennis rijden en ga zelf navigeren. Evi moet ook mee!",
      ]}
      finish={[
        "Het strand! Even lekker uitwaaien met Evi."
      ]}
      latitude={52.23377492468202} // Hondestrand Noordwijk
      longitude={4.420361504457221}
      nextStep={nextStep} />,

    <Video youtubeId="GQZMgQhn9ko" nextStep={nextStep} />, // Sophie

    <Puzzle
      title="Quizvraag 💡"
      question="De straat is vernoemd naar een koningin. Uit welk land kwam zij?"
      answer="zweden"
      nextStep={nextStep} />,

    <Text title="🏖️🏖️🏖️" nextStep={nextStep}>
      <p className="text-left my-2">Even lekker wandelen nu! Misschien een koffietje? Daarna pakken we de speurtocht weer op.</p>
    </Text>,
    <Video youtubeId="E_N3IjmJq4g" nextStep={nextStep} />, // Wouter & Aliene
    <Compass
      title="🌟🌿"
      start={[
        "Tijd om weer naar huis te gaan. Of lunchen?",
      ]}
      finish={[""]}
      latitude={52.206320} // Sterrenkroos
      longitude={4.632270}
      nextStep={nextStep} />,


    <Text title="🔢" nextStep={nextStep}>
      <p className="text-left my-2">Maar nu die code nog! Heb je de schatkist al gevonden trouwens?</p>
    </Text>,

    <Puzzle
      title="Versleutelen 🗝️"
      question="De volgende woorden zijn versleuteld. Aan jou om je eigen naam op dezelfde manier te versleutelen!"
      answer="rbjknu"
      nextStep={nextStep}>
      <p className="text-center font-bold">EVI = NER</p>
      <p className="text-center font-bold">DENNIS = MNWWRB</p>
      <p className="text-center font-bold">ISABEL = ?</p>
    </Puzzle>,

    <Puzzle
      title="Ontcijferd! 🏛️"
      question="Bij de vorige puzzel moest elk letter een aantal stappen worden verschoven. Dit heet een Caesarcijfer naar de Romeinse keizer. Het aantal stappen dat je moest verschuiven is het 2e cijfer van de code!"
      answer="9"
      nextStep={nextStep} />,

    <Text title="" nextStep={nextStep}>
      <img src={lisanne} alt="Lisanne" />
    </Text>,
    <Puzzle
      title="🔼🔼🔼🔼🔼🔼🔼"
      question="Hoeveel driehoeken staan er in de afbeelding hieronder?"
      answer="27"
      nextStep={nextStep}>
      <img src={triangles} alt="Driehoekspuzzel" />
    </Puzzle>,
    <Puzzle
      title="👍👍👍👍👍👍👍"
      question="Trek het eerste getal van het tweede getal af en je hebt het derde cijfer van de code!"
      answer="5"
      nextStep={nextStep} />,
    <Video youtubeId="vUTCVB4nvX4" nextStep={nextStep} />, // Wenze en Krista
    <Puzzle
      title="💯"
      question="Hoe vaak komt het getal 7 voor in de reeks van 1 tot en met 100?"
      answer="20"
      nextStep={nextStep} />,
    <Puzzle
      title="💯"
      question="Tel de twee getallen bij elkaar op en je hebt het laatste cijfer van de code!"
      answer="2"
      nextStep={nextStep} />,

    <Video youtubeId="Pg9mPof3pXc" nextStep={nextStep} />, // Max
    <Text title="The End 🎥">
      <p className="text-left my-2">Geniet van je cadeaus 💏. Hopelijk ben je op tijd voor je lessen 😊 Vanavond nog even ergens lekker uit eten!</p>
      <p className="text-center text-3xl my-4">6952</p>
    </Text>,

  ]

  /// CODE: 6952

  function nextStep() {
    if (currentStep + 1 < components.length) {
      setCurrentStep(currentStep + 1);
    }
  }

  function previousStep() {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  }

  return (
    <>
      {
        godMode
          ? <div className="absolute top-5 right-5">
            <button className="text-4xl inline-flex items-center mr-2 focus:outline-none" onClick={previousStep}>⬅️</button>
            <button className="text-4xl inline-flex items-center focus:outline-none" onClick={nextStep}>➡️</button>
          </div>
          : null
      }
      <div className="p-6 flex h-screen min-h-screen items-center justify-center">
        {components[currentStep]}
      </div>
      <div className="w-10 h-10 absolute top-5 left-5" onDoubleClick={() => setgodMode(true)}></div>
    </>
  );
}

export default App;
